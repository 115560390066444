import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

const HardwareComponent = (props) => {
  return (
    <Grid container>
      <Grid item xs={11}>
        <Grid container justifyContent='space-between' alignItems='flex-end'>
          <Typography>
            {props.label}
          </Typography>
          <Typography variant='h5'>
            {props.value} {props.unit}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        {props.idleBar ?
        <LinearProgress variant="determinate" value={0} style={{backgroundColor: 'black'}}/>
        : <LinearProgress variant="determinate" value={props.value} />}
      </Grid>
    </Grid>
  )
}

export default HardwareComponent