import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HardwareComponent from './HardwareComponent';
import { Box, TextField, CircularProgress, Typography, IconButton } from '@material-ui/core';
import { SpeakerPhone, Replay, PowerSettingsNew, FlashOn, FlashOff, Done, ColorLens, BrightnessLow } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  gridElement: {
    padding: '5px',
    borderRadius: '10px',
  },
  innerElement: {
    padding: '5px',
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  container: {
    borderRadius: '10px',
    backgroundColor: 'grey'
  },
  separator: {
    height: '70px',
    backgroundColor: 'black',
    width: '2px'
  },
  mainTitle: {
    width: 'fit-content',
    padding: '8px',
    borderRadius: '10px',
    backgroundColor: 'white',
    margin: '5px'
  },
  actionButton: {
    backgroundColor: 'lightblue',
    textTransform: 'none',
    margin: '0px 5px',
    borderRadius: '10px'
  }
}));

function Device(props) {

  const classes = useStyles();
  const [loading, setLoading] = useState(false)

  const [doOnce, setDoOnce] = useState(true)
  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
    }
  });

  const [sensorData, setSensorData] = useState(null)
  React.useEffect(() => {
    const timer = setInterval(() => { refresh() }, 1500)
    return () => {
      clearInterval(timer)
    }
  }, [doOnce])

  function refresh() {
    props.httpcall("get", "SensorData?deviceid=" + props.device.id, null, input => setSensorData(input))
  }

  const [currentCommand, setCurrentCommand] = useState(null)
  const [argument, setArgument] = useState(null)

  function executeCommand() {
    props.httpcall("get", "Command?deviceid=" + props.device.id + "&command=" + currentCommand + (argument ? "&parameters=" + argument : ''), null, input => callbackCommand(input))
  }
  function callbackCommand(input) {
    if (input) {
      setCurrentCommand(null)
      setArgument(null)
      setLoading(false)
      props.statusUpdate('success', 'OK')
    } else {
      props.statusUpdate('error', 'command failed')
    }
  }

  return (
    <Grid container style={{ padding: '5px' }} className={classes.container}>
      {sensorData != null ?
        <>
          {props.device.type === 0 ?
            <>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.mainTitle}>
                  {props.device.name} infos
                </Typography>
              </Grid>
              {sensorData.filter(s => s.component === 'CPU').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={3}>
                      <Grid container direction='column'>
                        <Typography>
                          CPU
                        </Typography>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'CPU' && s.type === 'Name').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={6}>
                          <HardwareComponent value={sensorData.find(s => s.component === 'CPU' && s.type === 'Temperature').value}
                            unit={sensorData.find(s => s.component === 'CPU' && s.type === 'Temperature').unit} label='Temperature' />
                        </Grid>
                        <Grid item xs={6}>
                          <HardwareComponent value={sensorData.find(s => s.component === 'CPU' && s.type === 'Load').value}
                            unit={sensorData.find(s => s.component === 'CPU' && s.type === 'Load').unit} label='Load' />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <Box fontStyle='italic' margin={2}>
                  No CPU found
                </Box>}
              {sensorData.filter(s => s.component === 'GPU').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={3}>
                      <Grid container direction='column'>
                        <Typography>
                          GPU
                        </Typography>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'GPU' && s.type === 'Name').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={6}>
                          <HardwareComponent value={sensorData.find(s => s.component === 'GPU' && s.type === 'Temperature').value}
                            unit={sensorData.find(s => s.component === 'GPU' && s.type === 'Temperature').unit} label='Temperature' />
                        </Grid>
                        <Grid item xs={6}>
                          <HardwareComponent value={sensorData.find(s => s.component === 'GPU' && s.type === 'Load').value}
                            unit={sensorData.find(s => s.component === 'GPU' && s.type === 'Load').unit} label='Load' />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
              {sensorData.filter(s => s.component === 'RAM').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={3}>
                      <Typography>
                        RAM
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={6}>
                          <HardwareComponent unit='Go' label='Capacity' idleBar={true}
                            value={(sensorData.find(s => s.component === 'RAM' && s.type === 'Load').value * sensorData.find(s => s.component === 'RAM' && s.type === 'Capacity').value / 100).toFixed(1) + ' / ' + sensorData.find(s => s.component === 'RAM' && s.type === 'Capacity').value} />
                        </Grid>
                        <Grid item xs={6}>
                          <HardwareComponent value={sensorData.find(s => s.component === 'RAM' && s.type === 'Load').value}
                            unit={sensorData.find(s => s.component === 'RAM' && s.type === 'Load').unit} label='Load' />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
            </>
            : <></>}
          {props.device.type === 1 ?
            <>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.mainTitle}>
                  {props.device.name} data
                </Typography>
              </Grid>
              {sensorData.filter(s => s.component === 'LightLevel').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={4}>
                      <Typography>
                        Ambiant Light
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'LightLevel').value} {sensorData.find(s => s.component === 'LightLevel').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
              {sensorData.filter(s => s.component === 'Temperature').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={4}>
                      <Typography>
                        Temperature
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'Temperature').value} {sensorData.find(s => s.component === 'Temperature').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
              {sensorData.filter(s => s.component === 'Humidity').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={4}>
                      <Typography>
                        Humidity
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'Humidity').value} {sensorData.find(s => s.component === 'Humidity').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
              {sensorData.filter(s => s.component === 'Pressure').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={4}>
                      <Typography>
                        Pressure
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'Pressure').value} {sensorData.find(s => s.component === 'Pressure').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
            </>
            : <></>}
            {props.device.type === 2 || props.device.type === 3 ?
            <>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.mainTitle}>
                  {props.device.name} data
                </Typography>
              </Grid>
              {sensorData.filter(s => s.component === 'State').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={4}>
                      <Typography>
                        State
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'State').value == '1' ? 'On' : 'Off'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
              {sensorData.filter(s => s.component === 'Brightness').length > 0 ?
                <Grid item lg={4} md={6} xs={12} className={classes.gridElement}>
                  <Grid container alignItems='center' justifyContent='space-around' className={classes.innerElement}>
                    <Grid item xs={4}>
                      <Typography>
                        Brightness
                      </Typography>
                    </Grid>
                    <Grid>
                      <div className={classes.separator} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Typography variant='h6'>
                          {sensorData.find(s => s.component === 'Brightness').value} {sensorData.find(s => s.component === 'Brightness').unit}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}
            </>
            : <></>}
        </>
        : <Box display="flex" alignItems="center" justifyContent="center" margin="10px">
          <>
            <CircularProgress />
          </>
        </Box>}
      {props.device.commands.length > 0 ?
        <>
          <Grid item xs={12}>
            <Typography variant='h5' className={classes.mainTitle}>
              {props.device.name} commands
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridElement}>
            <Grid container alignItems='center' className={classes.innerElement}>
              <Grid item xs={11}>
                {props.device.commands.includes('TurnOff') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('TurnOff')}
                    style={currentCommand === 'TurnOff' ? { backgroundColor: 'green' } : {}}>
                    <PowerSettingsNew />
                  </IconButton>
                  : <></>}
                {props.device.commands.includes('Reboot') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('Reboot')}
                    style={currentCommand === 'Reboot' ? { backgroundColor: 'green' } : {}}>
                    <Replay />
                  </IconButton>
                  : <></>}
                {props.device.commands.includes('Notify') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('Notify')}
                    style={currentCommand === 'Notify' ? { backgroundColor: 'green' } : {}}>
                    <SpeakerPhone />
                  </IconButton>
                  : <></>}
                {props.device.commands.includes('TurnOnLight') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('TurnOnLight')}
                    style={currentCommand === 'TurnOnLight' ? { backgroundColor: 'green' } : {}}>
                    <FlashOn />
                  </IconButton>
                  : <></>}
                {props.device.commands.includes('TurnOffLight') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('TurnOffLight')}
                    style={currentCommand === 'TurnOffLight' ? { backgroundColor: 'green' } : {}}>
                    <FlashOff />
                  </IconButton>
                  : <></>}
                {props.device.commands.includes('SetLightColor') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('SetLightColor')}
                    style={currentCommand === 'SetLightColor' ? { backgroundColor: 'green' } : {}}>
                    <ColorLens />
                  </IconButton>
                  : <></>}
                {props.device.commands.includes('SetBrightness') ?
                  <IconButton variant='contained' className={classes.actionButton} onClick={() => setCurrentCommand('SetBrightness')}
                    style={currentCommand === 'SetBrightness' ? { backgroundColor: 'green' } : {}}>
                    <BrightnessLow />
                  </IconButton>
                  : <></>}
              </Grid>
              <Grid item xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
                {currentCommand ?
                  <IconButton disabled={loading} className={classes.actionButton} style={{ color: 'green' }} onClick={() => { setLoading(true); executeCommand() }}>
                    <Done />
                  </IconButton>
                  : <></>}
              </Grid>
              {currentCommand ?
                <Grid container alignItems='center'>
                  {currentCommand === 'SetLightColor' || currentCommand === 'Notify' || currentCommand === 'SetBrightness' ?
                    <TextField style={{ marginTop: '15px' }} variant='outlined' value={argument} onChange={(event) => setArgument(event.target.value)} />
                    : <></>}
                </Grid>
                : <></>}
            </Grid>
          </Grid>
        </>
        : <></>
      }
    </Grid >
  )
}



export default Device;