import React from 'react';
import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Box,
  IconButton
} from '@material-ui/core';
import Device from './Device';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Sync, PowerSettingsNew } from '@material-ui/icons';


const Home = (props) => {

  const [devices, setDevices] = useState(null)
  const [loading, setLoading] = useState(false)

  const [doOnce, setDoOnce] = useState(true)
  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      getDevices()
    }
  });

  function getDevices() {
    setLoading(true)
    props.httpcall("get", "getdevices", null, callbackDevices)
  }

  function callbackDevices(data) {
    console.log("update")
    setDevices(data)
    setLoading(false)
  }

  return (
    <>
      {loading ? <CircularProgress /> : <></>}
      {devices ?
        <Grid container direction='column'>
          <Grid item>
            {devices.map(device => (
              <div key={device.id}>
                <DataComponent key={device.id} device={device} {...props} />
              </div>
            ))}
          </Grid>
        </Grid>
        : <Box fontStyle='italic' margin={2}>
          No device to display
        </Box>
      }
      <IconButton style={{ position: 'absolute', top: '1px', right: '1px', color: '#994466', backgroundColor: '#773355' }} onClick={() => { setDevices(null); props.httpcall("get", "getdevices", null, callbackDevices) }}>
        <Sync />
      </IconButton>
    </>
  );
}

const DataComponent = (props) => {
  const [doOnce, setDoOnce] = useState(true)
  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      setLoading(true)
      props.httpcall("get", "Command?deviceid=" + props.device.id + "&command=Ping", null, input => { setOnline(input); setLoading(false) })
    }
  });

  const [loading, setLoading] = useState(false)
  const [online, setOnline] = useState(false);
  const [deployed, setDeployed] = useState(false);

  function turnOn() {
    setLoading(true)
    props.httpcall("get", "Command?deviceid=" + props.device.id + "&command=TurnOn", null, input => { setOnline(input); setLoading(false) })
  }

  function updateAccordion(event, expanded) {
    setDeployed(expanded)
  }

  return <div style={{position: 'relative'}}>
    <Accordion disabled={!online} onChange={(event, expanded) => updateAccordion(event, expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Typography variant="h6" style={{ fontWeight: 550 }}>
            {props.device.name}
          </Typography>
          {loading ? <CircularProgress style={{ height: '25px', width: '25px' }} /> : <></>}
          {online ?
            <Typography variant="h6" style={{ border: '1px solid green', borderRadius: '5px', padding: '5px', color: 'green' }}>
              online
            </Typography>
            : <></>}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {deployed ?
          <Device {...props} />
          : <></>}
      </AccordionDetails>
    </Accordion>
    {props.device.commands.includes("TurnOn") && !online && !loading ?
      <IconButton style={{position: 'absolute', right: '5px', top: '4px', backgroundColor: 'lightgreen'}} onClick={turnOn}>
        <PowerSettingsNew style={{ color: 'green' }}/>
      </IconButton>
      : <></>}
  </div>
}

export default Home;
